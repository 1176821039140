import meter1 from "../assets/img/meter1.svg";
import meter2 from "../assets/img/meter2.svg";
import meter3 from "../assets/img/meter3.svg";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
      <div className="container">
          <div className="row">
              <div className="col-12">
                  <div className="skill-bx wow zoomIn">
                      <h2>Skills</h2>
                      <p>All the latest technologies.<br/>Bring your company into the future!</p>
                      <TrackVisibility>
                        {({ isVisible }) =>
                        <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                                <img src={meter1} alt="Web Dev" />
                                <h5>Web Development</h5>
                            </div>
                            <div className="item">
                                <img src={meter2} alt="AI" />
                                <h5>Artifical Intelligence</h5>
                            </div>
                            <div className="item">
                                <img src={meter3} alt="Marketing" />
                                <h5>Marketing</h5>
                            </div>
                            <div className="item">
                                <img src={meter2} alt="Data Engineering" />
                                <h5>Data Engineering</h5>
                            </div>
                            <div className="item">
                                <img src={meter1} alt="SEO" />
                                <h5>SEO</h5>
                            </div>
                            <div className="item">
                                <img src={meter2} alt="Cinematography" />
                                <h5>Cinematography</h5>
                            </div>
                            <div className="item">
                                <img src={meter1} alt="Passion" />
                                <h5>Passion</h5>
                            </div>
                            <div className="item">
                                <img src={meter3} alt="Confidence" />
                                <h5>Confidence</h5>
                            </div>
                        </Carousel>
                      </div>}
                    </TrackVisibility>
                  </div>
              </div>
          </div>
      </div> 
    </section>
  )
}
