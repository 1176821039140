import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard.js";
import lethub from "../assets/img/lethub.png";
import freshtrek from "../assets/img/freshtrek.png";
import northorca from "../assets/img/northorca.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "LetHub",
      description: "AI Real Estate Software",
      imgUrl: lethub,
      compUrl: "https://www.lethub.co/"
    },
    {
      title: "Fresh Trek",
      description: "Delicous home-made meals made with LOVE <3",
      imgUrl: freshtrek,
      compUrl: "https://freshtrek.ca/"
    },
    {
      title: "North Orca",
      description: "Aviation Industry Software",
      imgUrl: northorca,
      compUrl: "https://northorca.com/"
    },

  ];

  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p>Companies like yours, looking to solidify their futures in the 21st Century!</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">1</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">2</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">3</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <p>Your Company Here!</p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p>Or here? Welcome to the Sopkow Software Solutions Family!</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
