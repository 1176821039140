import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import bull from "../assets/img/bull.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import axios from 'axios';

export const Contact = () => {
  const formInitialDetails = {
    name: '',
    last_name: '',
    phone: '',
    message: ''
  }
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Send');
  const [email, setEmail] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const onFormUpdate = (category, value) => {
      setFormDetails({
        ...formDetails,
        [category]: value
      })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonText("Sending...");
    try {
      const response = await axios.post(
        "https://connect.mailerlite.com/api/subscribers",
        {
          email,
          fields: formDetails,
          groups: ["79110015466603740"]
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiNzQ1MDdhZWRmZGZkMzk5MzNlZjNkZDU5NDU3ZTU5YzRlNTM4OTMzZWYyZWU0MjYyNzVmMjNjMzc4YmQ3ZDQxMmY4MDNkMWQ3ZjAxNzgxZDkiLCJpYXQiOjE2NzUzNTQwMjEuMjMwNjg0LCJuYmYiOjE2NzUzNTQwMjEuMjMwNjg4LCJleHAiOjQ4MzEwMjc2MjEuMjI1NTczLCJzdWIiOiIzMzkyNDgiLCJzY29wZXMiOltdfQ.FDGYLrL4N5ucVW8F37Ixtu-kE8PHM0S9rRDrhkJJ8nmu_IgJXH8wVB8u7qVC_OOddLCEP2M_tdeuSS2nrVF1D7ppD7EdFbwJJCimi6J4RpBM6tYXjsuFU70IS2fSw0KQzFR6CbKBJzjymAvbTQq2tjFYhJuDQHWSG7M-loEv_2w2pvOxpuyc0UjjCsSQP9v4dAWJtno_x0A1QUQc0OGSU3bQx9GsVT1uUfMeRuF1NGPV7NAuAa1EaNZoflg56XlQ3cdYwdLeRhyjwSobzbJoL0kZeohff4qNRSkf_y4xUL8luVEC9ZlLVqW0NUih3SFaI2Fs0kv9QY617DkwVV4DNpSCJqXoQTZZ0KbWrRQapFJWUkizFCv5MYQzW9OLqklpN9POmkEa2PPLHe13v43XrpQUjIyt00E8qNia2uX9p_x4GdYn9R2FU7iMeIPylpV5vufrd3pwXgBHi06Ma-NLNnE7CNTUzAPgdhaPouspQfQcMIZNsXU81oQoW8HZNFvjzmMFZWPJBDB8Hw2UUWiz-ce7xJNei79S0tKfB4NPgzeqFNIxw6kDHfy1J8CcCz7M6Rp5Owvl6HOmmvvvZRHxCSjVXarqBHeGB-uo_573LCMACagg_6lzAFpnQkOF3v81p3KBmEGNnMUnVhIZIHy5SuNKd4qn3foprAKEw2FAeAM",
          },
        }
      );

      if (response.status === 201) {
        setSuccess(true);
        setFormDetails(formInitialDetails);
        setEmail("");
      } else {
        setError(response.data.error);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setSubmitting(false);
    }
    setButtonText("Success");
  };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={bull} alt="Bull"/>
                </div>
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h2>Questions?<br/>Drop us a line, any time!</h2>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" value={formDetails.name} placeholder="First Name" onChange={(e) => onFormUpdate('name', e.target.value)} />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="text" value={formDetails.last_name} placeholder="Last Name" onChange={(e) => onFormUpdate('last_name', e.target.value)}/>
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="email" value={email} placeholder="Email Address" onChange={(e) => setEmail(e.target.value)} />
                    </Col>
                    <Col size={12} sm={6} className="px-1">
                      <input type="tel" value={formDetails.phone} placeholder="Phone No." onChange={(e) => onFormUpdate('phone', e.target.value)}/>
                    </Col>
                    <Col size={12} className="px-1">
                      <textarea rows="6" value={formDetails.message} placeholder="Message" onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
                      <button type="submit"><span>{buttonText}</span></button>
                    </Col>
                    {
                      error &&
                      <Col>
                        <p className={success === false ? "danger" : "success"}>{error}</p>
                      </Col>
                    }
                  </Row>
                </form>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
