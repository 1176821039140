import { Container, Row, Col, Navbar } from "react-bootstrap";
import { MailerLiteForm } from "./MailerLiteForm.js";
import logo from "../assets/img/logo.png";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";

export const Footer = () => {
  const year = new Date().getFullYear();
  
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <MailerLiteForm />
          <Col size={12} sm={6}>
            <Navbar.Brand href="/">
              <img className="bot-logo" src={logo} alt="Logo" />
            </Navbar.Brand>
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://www.linkedin.com/in/brandon-sopkow" target="_blank" rel="noopener noreferrer"><img src={navIcon1} alt="Icon" /></a>
              <a href="https://www.facebook.com/people/SS-Solutions/100089772422919/" target="_blank" rel="noopener noreferrer"><img src={navIcon2} alt="Icon" /></a>
              <a href="https://www.instagram.com/dukendudes/" target="_blank" rel="noopener noreferrer"><img src={navIcon3} alt="Icon" /></a>
            </div>
            <p>Copyright ⓒ {year}. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}