import { Col } from "react-bootstrap";

export const ServiceCard = ({ title, imgUrl, list }) => {
  return (
    <Col Size={12} sm={6} md={4}>
      <div className="service-imgbx">
        <img src={imgUrl} />
        <div className="service-txtx">
            <h4>{title}</h4>
            {Array.isArray(list) && (
              <ul>
                {list.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            )}
        </div>
      </div>
    </Col>
  );
};