import { Col } from "react-bootstrap";

export const PortfolioCard = ({ title, description, imgUrl, compUrl }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="port-imgbx">
        <a href={compUrl} target="_blank" rel="noopener noreferrer">
          <img src={imgUrl} />
        </a>
        <div className="port-txtx">
          <a href={compUrl} target="_blank" rel="noopener noreferrer">
            <h4>{title}</h4>
            <span>{description}</span>
          </a>
        </div>
      </div>
    </Col>
  )
}