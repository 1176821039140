import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/about-me.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import { HashLink } from 'react-router-hash-link';
import TrackVisibility from 'react-on-screen';
import 'animate.css';


export const About = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ["Software Engineer.", "Entrepreneur.", "Full-stack Developer.", "UI/UX Designer.", "Marketing Guru.", "SEO Specialist.", "Machine Learning Engineer.", "Blockchain Enthusiest.", "Avid Hiker.", "Drone Racer.", "Dogs Best Friend.",  "Boyfriend of the year."];
  const period = 1000;
  
  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(100);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <section className="about" id="about">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h1>{`Hi, I'm Bran!`}</h1>
                <p>{`Founder of,`}</p>
                <h2 className="f-let">Sopkow</h2>
                <h2 className="f-let">Software</h2>
                <h2 className="f-let">Solutions</h2>
                <h3><span className="txt-rotate" dataPeriod="1000" data-rotate='[ "Tony", "Turntable" ]'><span className="wrap">{text}</span></span></h3>
                  <p>I graduated from the University of Victoria with distinction in one of the few Interdisciplinary Bsc's in Computer-Science & Psychology, focusing in my studies Artificial Intelligence and Web Applications. I have founded a number of companies since, and hope to use my experiences bringing <strong>your</strong> company to the next level!</p>
                  <HashLink to='/#connect'>
                    <button>Connect <ArrowRightCircle size={25} /></button>
                  </HashLink>
              </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={headerImg} alt="Header Img"/>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}