import { useState } from 'react';
import axios from 'axios';
import { Col, Row } from "react-bootstrap";

export const MailerLiteForm = () => {
  const [email, setEmail] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    try {
      const response = await axios.post(
        "https://connect.mailerlite.com/api/subscribers",
        {
          email,
          groups: ["79018464721766274"]
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiNzQ1MDdhZWRmZGZkMzk5MzNlZjNkZDU5NDU3ZTU5YzRlNTM4OTMzZWYyZWU0MjYyNzVmMjNjMzc4YmQ3ZDQxMmY4MDNkMWQ3ZjAxNzgxZDkiLCJpYXQiOjE2NzUzNTQwMjEuMjMwNjg0LCJuYmYiOjE2NzUzNTQwMjEuMjMwNjg4LCJleHAiOjQ4MzEwMjc2MjEuMjI1NTczLCJzdWIiOiIzMzkyNDgiLCJzY29wZXMiOltdfQ.FDGYLrL4N5ucVW8F37Ixtu-kE8PHM0S9rRDrhkJJ8nmu_IgJXH8wVB8u7qVC_OOddLCEP2M_tdeuSS2nrVF1D7ppD7EdFbwJJCimi6J4RpBM6tYXjsuFU70IS2fSw0KQzFR6CbKBJzjymAvbTQq2tjFYhJuDQHWSG7M-loEv_2w2pvOxpuyc0UjjCsSQP9v4dAWJtno_x0A1QUQc0OGSU3bQx9GsVT1uUfMeRuF1NGPV7NAuAa1EaNZoflg56XlQ3cdYwdLeRhyjwSobzbJoL0kZeohff4qNRSkf_y4xUL8luVEC9ZlLVqW0NUih3SFaI2Fs0kv9QY617DkwVV4DNpSCJqXoQTZZ0KbWrRQapFJWUkizFCv5MYQzW9OLqklpN9POmkEa2PPLHe13v43XrpQUjIyt00E8qNia2uX9p_x4GdYn9R2FU7iMeIPylpV5vufrd3pwXgBHi06Ma-NLNnE7CNTUzAPgdhaPouspQfQcMIZNsXU81oQoW8HZNFvjzmMFZWPJBDB8Hw2UUWiz-ce7xJNei79S0tKfB4NPgzeqFNIxw6kDHfy1J8CcCz7M6Rp5Owvl6HOmmvvvZRHxCSjVXarqBHeGB-uo_573LCMACagg_6lzAFpnQkOF3v81p3KBmEGNnMUnVhIZIHy5SuNKd4qn3foprAKEw2FAeAM",
          },
        }
      );

      if (response.status === 201) {
        setSuccess(true);
        setEmail("");
      } else {
        setError(response.data.error);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Col lg={12}>
      <div className="newsletter-bx wow slideInUp">
        <Row>
          <Col lg={12} md={6} xl={5}>
            <h3>Subscribe 🔥<br></br>Never miss the latest discounts!</h3>
          </Col>
          <Col md={6} xl={7}>
            <form onSubmit={handleSubmit}>
              <div className="new-email-bx">
                <input 
                  value={email} 
                  type="email" 
                  onChange={(e) => setEmail(e.target.value)} 
                  placeholder="Email Address"
                  required 
                />
                <button className="join" type="submit" disabled={submitting}>
                  {success ? "Success!" : "Join!"}
                </button>
                {error && <div>{error}</div>}
              </div>
            </form>
          </Col>
        </Row>
      </div>
    </Col>
  );
};