import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from './components/NavBar';
// import Player from './components/Player.js';
import { Footer } from './components/Footer';
import { Home } from "./components/Home.js";
import { Portfolio } from "./components/Portfolio.js";
import { Services } from "./components/Services.js";
import { Merch } from "./components/Merch.js";
import { About } from "./components/About.js";
import { Routes, Route} from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <NavBar/>
      {/* <Player/> */}
      <Routes>
          <Route path='/' element={ <Home/> } />
          <Route path='/portfolio' element={ <Portfolio/> } />
          <Route path='/services' element={ <Services/> } />
          <Route path='/merch' element={ <Merch/> } />
          <Route path='/about' element={ <About/> } />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
