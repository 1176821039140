import ssstier from "../assets/img/ssstiers.jpg";
import sstier from "../assets/img/sstiers.jpg";
import stier from "../assets/img/stiers.jpg";
import marketing from "../assets/img/marketing.jpg";
import seo from "../assets/img/seo.jpg";
import dataeng from "../assets/img/dataeng.jpeg";
import { Container, Row, Col, Tab} from "react-bootstrap";
import { PortfolioCard } from "./PortfolioCard.js";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { ServiceCard } from './ServiceCard.js';

export const Services = () => {

  const packages = [
    {
      title: "SSS Tier",
      imgUrl: ssstier,
      list: ["Custom React Website", "Google Cloud Hosting", "CI-CD Pipeline", "Project Source Code", "Unlimited Revisions"]
    },
    {
      title: "SS Tier",
      imgUrl: sstier,
      list: ["Custom React Website", "Google Cloud Hosting", "Docker Image", "Project Source Code", "Limited Revisions"]
    },
    {
      title: "S Tier",
      imgUrl: stier,
      list: ["Custom React Website", "Bring your own Domain", "Host your own Website", "Upgrade Existing Website", "Few Revisions"]
    },
    {
      title: "Data Engeering",
      imgUrl: dataeng,
      list: ["Custom AI Solutions", "Data Pipelines", "Analytics", "Visualizations", "Actionable Insights"]
    },
    {
      title: "Marketing",
      imgUrl: marketing,
      list: ["Google Ads", "Facebook Ads", "Email Campaigns", "Custom Mail Servers", "Contextual Marketing"]
    }, 
    {
      title: "SEO",
      imgUrl: seo,
      list: ["Google Analytics", "Keyword Ranking", "Competition Analysis", "Digital PR Leveraging", "Link Building"]
    }
  ];

  return (
    <section className="service" id="service">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Services</h2>
                <p>Select the right solution for you!</p>
                <Tab.Container id="service-tabs" defaultActiveKey="first">
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          packages.map((services, product) => {
                            return (
                              <ServiceCard
                                key={product}
                                {...services}
                              />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}