import bwcupside from "../assets/img/bwcupside.jpeg";
import bwcupfront from "../assets/img/bwcupfront.jpeg";
import bwcupright from "../assets/img/bwcupside-right.jpeg";
import colorcupside from "../assets/img/colorcupside.jpeg";
import colorcupfront from "../assets/img/colorcupfront.jpeg";
import colorcupright from "../assets/img/colorcupside-right.jpeg";
import colorpen from "../assets/img/pen.jpeg";
import colbusiness from "../assets/img/colorcardfront.jpg";
import colbusinessback from "../assets/img/colorcardback.jpg";
import bwbusiness from "../assets/img/bwcardfront.jpg";
import bwbusinessback from "../assets/img/bwcardback.jpg";
import bwhat from "../assets/img/bwhat.jpeg";
import { Container, Row, Col, Tab} from "react-bootstrap";
import { PortfolioCard } from "./PortfolioCard.js";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { MerchCard } from './MerchCard.js';

export const Merch = () => {

  const merchandise = [
    {
      title: "Create your own!",
      imgUrl: colorcupside,
    },
    {
      title: "Pens",
      imgUrl: colorpen,
    },
    {
      title: "Brand Mugs",
      imgUrl: bwcupright,
    },
    {
      title: "Business Cards",
      imgUrl: bwbusiness,
    },
    {
      title: "Custom Clothing",
      imgUrl: bwhat,
    },
    {
      title: "Personalized QR Codes!",
      imgUrl: colbusinessback,
    },
  ];

  return (
    <section className="merch" id="merch">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Merch</h2>
                <p>Support us, or create your own customised merchandise!</p>
                <Tab.Container id="merch-tabs" defaultActiveKey="first">
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          merchandise.map((merchs, item) => {
                            return (
                              <MerchCard
                                key={item}
                                {...merchs}
                              />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}