import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { PortfolioCard } from "./PortfolioCard.js";
import { Testimonial } from "./Testimonial.js";
import lethub from "../assets/img/lethub.png";
import freshtrek from "../assets/img/freshtrek.png";
import northorca from "../assets/img/northorca.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Portfolio = () => {

  const projects = [
    {
      title: "LetHub",
      description: "AI Real Estate Software",
      imgUrl: lethub,
      compUrl: "https://www.lethub.co/"
    },
    {
      title: "FreshTrek",
      description: "Freeze Dried Meals",
      imgUrl: freshtrek,
      compUrl: "https://freshtrek.ca/"
    },
    {
      title: "North Orca",
      description: "Aviation Industry Software",
      imgUrl: northorca,
      compUrl: "https://northorca.com/"
    },

  ];

  return (
    <div>
      <section className="portfolio" id="portfolio">
        <Container>
          <Row>
            <Col size={12}>
              <TrackVisibility>
                {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                  <h2>Portfolio</h2>
                  <p>Companies like yours, looking to solidify their futures in the 21st Century!</p>
                  <Tab.Container id="port-tabs" defaultActiveKey="first">
                    <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                      <Nav.Item>
                        <Nav.Link eventKey="first">1</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">2</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">3</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                      <Tab.Pane eventKey="first">
                        <Row>
                          {
                            projects.map((portfolio, index) => {
                              return (
                                <PortfolioCard
                                  key={index}
                                  {...portfolio}
                                />
                              )
                            })
                          }
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <p>Your Company Here!</p>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <p>Or here? Welcome to the Sopkow Software Solutions Family!</p>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>}
              </TrackVisibility>
            </Col>
          </Row>
        </Container>
      </section>
      {/* < Testimonial /> */}
    </div>
  )
}