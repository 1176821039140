import { Banner } from "./Banner.js";
import { Skills } from "./Skills.js";
import { Projects } from "./Projects.js";
import { Contact } from "./Contact.js";

export const Home = () => {
    return (
      <div className="Home">
        <Banner />
        <Skills />
        <Projects />
        <Contact />
      </div>
    );
}